/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&display=swap');



@font-face {
    font-family: 'Muli';
    src: url('../../fonts/text-font/Muli.ttf') format("truetype");
    font-weight: 600;
    font-style: normal;
  }

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 500;
    src: local('Muli Regular'), url('../../fonts/text-font/Muli-Regular.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 600;
    src: local('Muli SemiBold'), url('../../fonts/text-font/Muli-SemiBold.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 700;
    src: local('Muli Bold'), url('../../fonts/text-font/Muli-Bold.woff') format('woff');
    }

// fonts
$base-font-size: 15;
$base-font: 'Muli';
$heading-font: 'Roboto Slab', serif;



// color
$dark-gray: #212158;
$body-color: #4f555a;
$white: #fff;
$light: #8188a9;
$black: #000;
$cyan: #848892;

$theme-primary-color: #ef5f34;
$theme-primary-color-s2: #5dc4b8;
$body-bg-color: #fff;
$section-bg-color: #fef6f3;
$section-bg-color2: #e9fafa;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #e1e1e1;
$border-color-s2: #d8e0f1;
